import { FC, PropsWithChildren } from "react";

import { Flex, Box, Heading } from "@chakra-ui/react";

import Image from "@components/Image";
import { VERTICAL_ALIGN_VALUE } from "@components/ResourceView/components/constants";
import { SideImageContext } from "@components/ResourceView/components/utils";
import { useIsMobile } from "@utils/hooks/hooks";
import { IBlock } from "features/cms/types";

import { getBlockWithImageStyles } from "../../utils/blockWithImageUtils";

interface IBlockWithImageProps {
  block: IBlock;
}

export const MainFeatureBlock: FC<PropsWithChildren<IBlockWithImageProps>> = ({ block, children }) => {
  const { code, width, backgroundColor, image, name } = block;
  const isMobile = useIsMobile();
  const { blockContainer, paddingByImagePosition } = getBlockWithImageStyles(block, isMobile);

  return (
    <Box py={4} width={{ base: "full", sm: "90%", lg: `calc(${width}% - 3rem)` }} mx="auto">
      <Flex bgColor={backgroundColor} gap={8} overflow="hidden" {...blockContainer}>
        <Heading
          as="h3"
          style={{
            color: "black",
            fontSize: "24px",
            alignSelf: "center",
            textAlign: "center",
            lineHeight: "28px",
          }}
        >
          {name}
        </Heading>
        <Flex
          bg="gray.50"
          style={{
            borderRadius: "1rem",
            height: "440px",
            display: "flex",
            position: "relative",
            overflow: "hidden",
          }}
        >
          {image?.path && (
            <Image src={image.path} alt={name} draggable={false} objectFit="cover" layout="fill" priority />
          )}
        </Flex>
        <Flex
          w="full"
          flex={1}
          key={code}
          flexDir="column"
          alignSelf={VERTICAL_ALIGN_VALUE[block.verticalAlign || "top"]}
          style={{
            color: "#000000B2",
            fontSize: "1.125rem",
          }}
        >
          <Box {...paddingByImagePosition} pl="0 !important" pr="0 !important" textAlign="center">
            <SideImageContext.Provider value={block?.image?.path ?? null}>{children}</SideImageContext.Provider>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};
